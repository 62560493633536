<template>
  <section-place-info
    :business="business"
    :place="place"
    :title="translations.contactPlaceInfoThirdTitle"
    :left="!$vuetify.breakpoint.mdAndUp"
    scroll-id="placeThirdCity"
  />
</template>

<script>
  import SectionPlaceInfo from '@/views/sections/reusable/PlaceInfo'
  import { latLng } from 'leaflet'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'

  export default {
    name: 'SectionContactPlaceInfoThird',
    components: {
      SectionPlaceInfo,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      business () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: 'Address',
            text: this.translations?.contactPlaceInfoThirdLocale,
          },
          {
            icon: 'mdi-cellphone',
            title: 'Phone',
            text: documentToHtmlString(this.translations?.contactPlaceInfoThirdPhones),
          },
          {
            icon: 'mdi-email',
            title: 'Email',
            text: this.translations?.contactPlaceInfoThirdEmail,
          },
        ]
      },
    },
    data: () => ({
      title: 'ODDZIAŁ W PŁOCKU',
      place:
        {
          geo: latLng(52.594140, 19.653090),
          address: 'ul. Witolda Zglenickiego 50 F \b 09-400 Płock',
        },

    }),
  }
</script>
